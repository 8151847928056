body {
  background-color: black;
  margin: 0;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
}

#layout {
  height: 100vh;
  padding: 20px;
}

* {
  font-family: Favorit-Regular, Helvetica, sans-serif !important;
  box-sizing: border-box;
}

*::selection {
  background: #02208f; 
}

*::-moz-selection {
  background: #02208f;
}

@font-face {
  font-family: "Favorit-Regular";
  src: url("./fonts/Favorit-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Favorit-Medium";
  src: url("./fonts/Favorit-Medium.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mercury-Bold";
  src: url("./fonts/MercuryDisplay-Bold.otf");
  font-weight: bold;
  font-style: normal;
}
